.productImages{
    width: 75px;
    height: 75px;
    margin: 5px;
    border: 3px solid #8b98ac;
    border: none;
}
  
.productImagesNoZoom{
    width: 75px;
    height: 75px;
    margin: 5px;
    border: 3px solid #8b98ac;
    border: none;
}

.productImagesNoZoom:hover{
width: 75px;
height: 75px;
margin: 5px;
border: 3px solid #8b98ac;
}

.productImages:hover{
cursor: zoom-in;
}

.productImagesSelected{
position: absolute;
width: 350px;
height: 350px;
transition: 0.05s;
cursor: zoom-out;
z-index: 1;
}

.productImagesContainer{
display: inline-block;
position: relative;
}

.noLeadImage{
width: 75px;
height: 75px;
margin: 5px;
border: 3px solid #8b98ac;
border: none;
}

.tableDescriptionImageDiv{
display: flex;
flex-wrap: wrap;
width: 800px;
}

.imageDeleteButton{
font-size: 10px;
position: absolute;
top: 0px;
right: 0px;
height: fit-content;
width: fit-content;
margin: 5px;
background-color: #c7c7c7;
border: 1px solid black;

}

.imageDeleteButton:active{
border: 2px solid rgba(216, 3, 3, 0.877);
color: rgba(216, 3, 3, 0.877);
}