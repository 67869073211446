.standardButton{
  padding: 5px;
  margin: 5px;
  display: inline-block;
  background-color: rgb(42, 42, 42);
  color: #ffffff;
  border-color: 2px solid rgb(42, 42, 42);
  border-radius: 6px 6px 6px 6px;
}

.descriptionModal .buttonContainer {
    text-align:right;
}

.editButton{
    margin-right: 5px;
    padding: 5px;
    margin-top: 5px;
    background-color: rgb(42, 42, 42);
    color: #ffffff;
    border-color: 2px solid rgb(42, 42, 42);
    border-radius: 8px 8px 8px 8px;
}

.buttonMenu {
    padding: 5px;
    margin: 10px;
    display: inline-block;
    background-color: rgb(42, 42, 42);
    color: #ffffff;
    border-color: 2px solid rgb(42, 42, 42);
    border-radius: 8px 8px 8px 8px;
}

button:hover{
  cursor: pointer;
}

.channelSelectorContainer button{
    padding: 5px;
    margin: 2px;
    margin-top: 5px;
    display: inline-block;
    background-color: rgb(42, 42, 42);
    color: #ffffff;
    border-color: 2px solid rgb(42, 42, 42);
    border-radius: 8px 8px 8px 8px;
}


.tableDescriptionImageButtons .customImageUploadButton{
    padding: 5px;
    margin: 5px;
    display: block;
    background-color: rgb(42, 42, 42);
    color: #ffffff;
    border-color: 2px solid rgb(42, 42, 42);
    border-radius: 6px 6px 6px 6px;
}

.tableDescriptionImageButtons .customImageUploadButtonSelected{
    padding: 5px;
    margin: 5px;
    display: block;
    background-color: rgb(0, 180, 114);
    color: #ffffff;
    border-color: 2px solid rgb(42, 42, 42);
    border-radius: 6px 6px 6px 6px;
}

.dropdownSubTableButton{
  padding: 5px;
  margin-left: 0px;
  width: 100%;
  display: inline-block;
  background-color: rgb(42, 42, 42);
  color: #ffffff;
  border-color: 2px solid rgb(42, 42, 42);
  border-radius: 6px 6px 6px 6px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownWrapper .dropdownContentExtend{
 max-height: 100px !important;
 min-width: 150px !important;
}

.dropdownButtonContainer{
  padding: 5px;
}


.dropdownWrapper li{
  background-color: #ffffff !important;
}

.dropdownWrapper li:hover{
  background-color: #b4b4b4 !important;
}


/* .standardButton,
.inlineButton,
.createNew {
  padding: 5px;
  margin: 10px;
  display: inline-block;
  background-color: rgb(42, 42, 42);
  color: #ffffff;
  border-color: 2px solid rgb(42, 42, 42);
  border-radius: 8px 8px 8px 8px;
}

.standardButtonTriggered{
  padding: 5px;
  margin: 10px;
  display: inline-block;
  background-color: rgb(79, 146, 126);
  color: #ffffff;
  border-color: 2px solid rgb(42, 42, 42);
  border-radius: 8px 8px 8px 8px;  
}

.inlineButtonContainer .inlineButton{
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.inlineButtonContainer .inlineButton:hover{
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0px;
  text-overflow: ellipsis;
  overflow: visible;
  white-space: normal;
}


.inlineButtonContainer{
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
}

.editButton{
  margin-right: 5px;
  padding: 5px;
  margin-top: 5px;
  background-color: rgb(42, 42, 42);
  color: #ffffff;
  border-color: 2px solid rgb(42, 42, 42);
  border-radius: 8px 8px 8px 8px;
}

.disabledButton{
  margin-right: 5px;
  padding: 5px;
  margin-top: 10px;
  background-color: rgba(126, 126, 126, 0.397);
  color: #ffffff98;
  border-color: 2px solid rgb(42, 42, 42);
  border-radius: 8px 8px 8px 8px;    
}



.editButtonTriggered{
  margin-right: 5px;
  padding: 5px;
  margin-top: 10px;
  background-color: rgb(79, 146, 126);
  color: #ffffff;
  border-color: 2px solid rgb(42, 42, 42);
  border-radius: 8px 8px 8px 8px;
  
}

.standardImageButton{
  padding: 5px;
  margin: 2px;
  display: inline-block;
  background-color: rgb(42, 42, 42);
  color: #ffffff;
  border-color: 2px solid rgb(42, 42, 42);
  border-radius: 8px 8px 8px 8px;
  width: 100%;
}

.inlineButtonTableDescription{
  padding: 5px;
  margin: 10px;
  display: inline-block;
  position: absolute;

  background-color: rgb(42, 42, 42);
  color: #ffffff;
  border-color: 2px solid rgb(42, 42, 42);
  border-radius: 8px 8px 8px 8px;
}


.standardButton, .disabledButton, .standardButtonTriggered {
  float: right;
}

.genericContainer .standardButton,.genericContainer .disabledButton {
  display: inline-block;
  float: none;
}

.generalButton{
  display: block;
  margin-left: 15px;
}

.buttonRow {
  display: inline;
  position: relative;
} */
