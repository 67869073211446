.descriptionModalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6); /* Optional: to add a background overlay */
    z-index: 1000; /* Ensure it is on top of other content */
}

.descriptionModalContainer table {
    width: 100%;
    height: 100%;
    border-collapse: collapse; /* Optional: to remove default table spacing */
}

.descriptionModalContainer td {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: auto !important; /* Adjust width according to content */
    height: 100% !important; /* Ensure the td takes the full height */
    max-width: none !important; /* Override max-width restriction */
    border: none !important;
}

.descriptionModalWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    max-width: 90vw; /* Maximum width */
}

.descriptionModal {
    background: #98a9c0;
    border: 2px solid black;
    max-width: 90vw; /* Ensure the modal does not exceed 90% of the viewport width */
    max-height: 90vh; /* Ensure the modal does not exceed 90% of the viewport height */
    width: auto;
    height: auto;
    overflow: auto;
    box-sizing: border-box; /* Include padding and border in element's total width and height */

}

.descriptionModalContainer .mainDescriptionTable{
    width: auto;
    margin-right: 15px;
    border-collapse: separate; /* Use 'separate' instead of 'collapse' for border-radius to work */
    border-spacing: 0; /* Remove spacing between cells */
    overflow: hidden; /* Ensure the table respects the border-radius */
    
}

.descriptionModalContainer .mainDescriptionTable .mainDescriptionTableTitle{
    background: #6e90c2;
    color: rgb(255, 255, 255);
    width: fit-content;
}

.descriptionModalContainer .mainDescriptionTable th{
    min-width: 100px !important;
}

.descriptionModalContainer .mainDescriptionTable th:hover{
    min-width: 100px !important;
}


.descriptionModalContainer .mainDescriptionTable .mainDescriptionTableEntry{
    padding-left: 10px !important;
    padding-right: 10px !important;
}


.editButtonHolder{
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    translate:calc(-50px)

}

.editButtonHolder .editButton{
    width: 50px;
}

.mainDescriptionTableEntry .inTableInput {
    text-align: left;
    background: none;
    border: none;
    font-weight: bold;
    color: black;
    font-size: 12px;
    width: calc(100% - 5px);
    padding: 0px;
    margin: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .mainDescriptionTableEntry .inTableInputTriggered {
    font-size: 12px;
    width: calc(100% - 5px);
    padding: 0px;
    margin: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }


.mainDescriptionTableEntry .inTableInput::placeholder {
    color: black;
}
  
.tableDescriptionImageContainer .mainDescriptionTable .mainDescriptionTableEntry .inTableInput:hover{
    white-space: normal !important;
    overflow: visible !important;
    height: 50px;
}
