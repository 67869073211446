.navbarContainer {
  height: 100%;
}

.navbarContainer nav {
  border: 2px solid rgb(42, 42, 42);
  background-color: rgb(42, 42, 42);
  padding-right: 0;
  padding-left: 0;
  padding-top: 5px;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 15vw;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: table-cell;
  /* animate slider */
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.navbarContainer nav:target {
  transform: translateX(0);
}

.navbarContainer nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.navbarContainer nav li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.navbarContainer nav li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 2vw;
  font-weight: 600;
  display: block;
  width: 100%;
}

.navbarContainer nav li a p {
    font-size: 1.5vw;
    display: inline-block;
    padding-left: 0.5vw;
    padding-bottom: 1vw;
    padding-top: 1vw;
    margin-top: 0vw;
    margin-bottom: 0vw;
}

.navbarContainer nav li a i {
    font-size: 1.25vw;
    display: inline-block;
}

.navbarContainer nav button:focus {
  background-color: #3274d6;
  color: black;
  font-weight: 400;
  width: 100%;
}

.navbarContainer nav .dropdown-menu li a:hover,
.navbarContainer nav .dropdown-menu-clicked li a:hover {
  background-color: #0d194e;
  color: #fa826f;
  font-weight: 600;
  width: 100%;
}

.navbarContainer nav li .dropdown-menu-clicked a {
  color: #ffab9e;
  text-decoration: none;
  font-size: 1vw;
  font-weight: 600;
  display: block;
  width: 100%;
}

.navbarContainer .selected {
  color: #ffab9e;
  background-color: #435165;
  border-top: 4px solid #3274d6;
  border-bottom: 4px solid #3274d6;
}

.navbarContainer .close:hover {
  background-image: none;
  background-color: transparent;
}

.navbarContainer .close {
  padding-right: 0;
  padding-top: 0;
  padding-left: 5px;
  margin: 0;
}

.navbarContainer nav ul ul a {
  font-size: 1vw;
  padding: 0.5rem 2rem;
  color: #f0d2d2;
  width: 100%;
}

.navbarContainer .dropdown {
  position: relative;
  cursor: pointer;
  font-size: 1.4vw;
  padding: 0.5rem 0rem;
  width: 100%;
}

.navbarContainer .dropdown a i:hover {
  color: #ffab9e;
}

.navbarContainer .dropdown-menu {
  display: none;
  position: relative;
  z-index: 1000;
  width: 100%;
}

.navbarContainer .dropdown-menu-clicked {
  display: block;
  position: relative;
  z-index: 1000;
}

.navbarContainer .dropdown:hover .dropdown-menu {
  display: block;
}

.navbarContainer-hamburger,
.navbarContainer .close {
  font-size: 1.5rem;
  padding-left: 1;
  color: #ffffff;
  padding: 0.5%;
  z-index: 1;
  background-color: rgb(42, 42, 42);
  border-radius: 8px 8px 8px 8px;
}

.navbarContainer-hamburger {
  position: absolute;
  border: 2px solid rgb(42, 42, 42);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.navbarContainer-close{
  margin-left: 15px;
}

.navbarContainer-close h4{
  display:inline-block;
  margin-left: 5px;
}

.navbarContainer-dropdown a i p:hover{
  color: #ffab9e;
}

.navbarContainer-dropdown a i:hover{
  color: #ffab9e;
}

.navbarContainer-close i:hover{
  color: #ffab9e;
}