.descriptionModalSubTableContainer{
  min-width: 1000px;
  max-width: 1500px;
}

.descriptionModalSubTableContainer .shortSubTableInfoTable{
    position: relative;
    text-overflow: unset;
    overflow:auto;
    white-space:normal;
    margin: 0%;
    padding: 0%;
    margin-top: 15px;
    border-collapse: collapse;
    left: calc(25%);
    width:   fit-content;
    min-width: 500px;
    max-width: 1000px;
  }

.descriptionModalSubTableContainer p{
  position: relative;
  left: 50%;
  margin: 2px;
  width: fit-content;
  text-align: right;
}

.subTableHeader{
  background-color: #6e90c2;
  border-collapse: collapse;
  color: white;
}


.subTableTableFooter{
  background-color: #7c8a9b;
}

.deleteButtonContainer{
    border:none !important;
  }

.subTableAddNewButton{
    border:none !important;
    width: fit-content;
}

.subTableAddNewButton button{
    border:none !important;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px !important;
    padding: 5px !important;
}

.descriptionModalSubTableContainer .longSubTableInfoTable{
  margin-top: 15px;
  margin: 10px;
  border-collapse: collapse;
  table-layout: fixed;
  min-width: 1000px;
  max-width:1200px;
}

.longSubTableInfoTable .subTableAddNew{
  width: 100%;
}

.subTableAddNew .subTableAddNewInput{
  width: 100%;
  box-sizing: border-box;
}

.longSubTableInfoTable .subTableHeader{
  min-width: 75px;
  width: fit-content;
}

.subTableEntryAdded{
  background-color: #ffc376;
}