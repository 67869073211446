.confirmBoxContainer {
    position: fixed; /* or position: absolute; depending on your layout needs */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Adjust this value to ensure it's above other elements */
}

.confirmBox-main{
    background: #98a9c0;
    border: 1px solid black;
    width: fit-content;
    padding: 20px;
    align-items: center;

}

.confirmBox-main .errorMessage{
    padding: 5px;
    width: fit-content;
    left: 0;
    text-align: left;
}

.confirmBox-main p{
    text-align: left;
}

.button-container {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    width: 100%; /* Ensure buttons take full width of .confirmBox-main */
}

.confirmBox-main h1{
    color: white;
    padding: 2px;
    margin-top: 0px;
    text-align: center;
}

.standardButton {
    margin-left: 10px; /* Optional: Add margin between buttons */
}

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    z-index: -1; /* Start with a lower z-index to hide it initially */
}