* 
/********************************************************************************************************/

/********************************************************************************************************/
/*GENERAL*/
{
  font-family: -apple-system, BlinkMacSystemFont, "segoe ui", roboto, oxygen,
    ubuntu, cantarell, "fira sans", "droid sans", "helvetica neue", Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #98a9c0;
  margin: 0;
}

body::-webkit-scrollbar{
  display: none;
}

html, body {
  height: 100%;
  margin: 0px;

}

/********************************************************************************************************/

/********************************************************************************************************/
/*NAVBAR*/

@import "navbar.css";

/********************************************************************************************************/

/********************************************************************************************************/
/*BANNER IMAGE*/
.bannerImage {
  display: block;
  border-bottom: 2px solid #435165;
  color: #000000;
  background-image: url(../public/mmilo-header-image-7.jpeg);
  background-size: cover;
  z-index: -1;
  position: absolute;
  height: 25%;
  width: 100%;
  opacity: 80%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bannerImage span {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 25px;
  border: 2px solid rgb(42, 42, 42);
  color: white;
  background-color: rgb(42, 42, 42);
  stroke: black;
  font-family: "Font Awesome 5 Free";
  vertical-align: bottom;
  height: fit-content;
  box-shadow: 0 8px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px 8px 8px 8px;
  padding: 5px;
}

/********************************************************************************************************/
/********************************************************************************************************/
/*FOOTER*/

.footer {
  /* Footer styles */
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  z-index: -1;
}

.footer p{
  color: #ffffff;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

/********************************************************************************************************/
/********************************************************************************************************/  
/*TABLE*/
@import "mainTable.css";

.spacerDiv {
  display: block;
  position: relative;
  height: 25vh;
  width: 100%;
}

/********************************************************************************************************/

/********************************************************************************************************/
/*MODAL*/
@import "descriptionModal.css";

.modalTitle {
  display: inline-block;
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  margin: 0px;
  text-indent: 8px;
  background-color: #6e90c2;
  height: 40px;
  margin-bottom: 5px;
  color: white;
}

.productDescription {
  color: #2b2b9b;            /* Link color */
  cursor: pointer;       /* Pointer cursor on hover */
}

.productDescription:hover {
  color: #23237e;            /* Link color */
  cursor: pointer;       /* Pointer cursor on hover */
}


/********************************************************************************************************/

/********************************************************************************************************/
/*IMAGE DIV*/
@import "descriptionModalImage.css";

.tableDescriptionImageDiv{
  display: inline-block;
  max-width: inherit;
  width: 100%;
}

/********************************************************************************************************/

/********************************************************************************************************/
/*SUBTABLES*/
@import "subTable.css";

.deleteButton {
  display: inline-block;
  background-color: #00000000;
  color: #a50000;
  font-weight: bold;
  border-color: rgb(103, 103, 103);
  border-radius: 24px;
  border:rgb(42, 42, 42);
  padding-left: 3px;
  padding-right: 3.1px;
  font-size: 12px;
}

.deleteButtonSubTable{
  color: #a50000;
  font-weight: bold;
  display: inline-block;
  background-color: #00000000;
  border: none;

}

.disabledDeleteButton {
  display: inline-block;
  background-color: #00000000;
  color: #ffffff98;
  font-weight: bold;
  border-color: rgb(103, 103, 103);
  border-radius: 24px;
  border:rgb(42, 42, 42);
  padding-left: 3px;
  padding-right: 3.1px;
  font-size: 12px;
}

.deleteButtonContainer{
  border: none !important;
}

.deleteButtonContainer:hover{
  border: none !important;
}

/********************************************************************************************************/

/********************************************************************************************************/
/*PRODUCT IMAGES*/
@import "productImages.css";

/********************************************************************************************************/

/********************************************************************************************************/
/*BUTTONS*/

@import "buttons.css";

/********************************************************************************************************/

/********************************************************************************************************/
/*CONFIRM BOX*/

@import "confirmBox.css";



/********************************************************************************************************/

/********************************************************************************************************/
/* SPINNER */

.showHideLoading{
  width: 100px;
  margin: 'auto';
  display: 'block';
}

.spinnerModal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6); /* Optional: to add a background overlay */
  z-index: 10000; /* Ensure it is on top of other content */
}

.display-block-spinner{
  display: block;
}

.display-none{
  display: none;
}

@import "spinner.css";

/********************************************************************************************************/

/********************************************************************************************************/
/*EDIT MODAL*/
.inputEditContainer {
  margin: 0%;
  padding: 2px;
}
  
.productDescriptionTableAltDim input {
  width: 35px;
}

.modal-main .fileSelect {
  display: inline;
  width: fit-content;
  position: relative;
  background-color: #c4c5c5;
}

.cellTableColumn {
  display: inline-block;
  padding: 0px;
  text-align: left;
  text-indent: 0;
  margin: 0px;
  
}

.cellTableColumn ul {
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid #000000;

}

.cellTableColumn h2 {
  padding-left: 15px;
  padding-right: 15px;
  margin: 0px;
  background-color: #6e90c2;
  color: rgb(229, 229, 229);
  border: 1px solid #000000;
}

.cellTable{
  display:flex;
  font-size: 12px;
  max-width: fit-content;
  /**overflow-x: scroll;**/
  margin: 15px;
  padding-left: 15px; 
  padding-right: 15px;
}

.cellTableColumn{
  min-width: fit-content;
}
  /********************************************************************************************************/

  /********************************************************************************************************/
  /*SELECT BOX FOR CONTAINER MANAGEMENT*/
  .optionsContainer .option input{
    width: 100%;
  }
  
  .selectSkuContainer{
    padding-left: 10px;
  }
  
  .selectSkuContainer .selectedSku{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    background: #2f3640;
    border-radius: 8px;
    position: relative;
    order: 0;
    color: #f5f6fa;
  }
  
  .selectSkuContainer .selectedSku::after{
    content:"";
    background: url("img/Arrow-down.svg");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    height: 100%;
    width: 32px;
    right: 20px;
    top: 8px;
    transition: all 0.4s;
    
  }
  
  .selectBox{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: fit-content;
  }
  
  .skuColumnAddSKU{
    background-color: #2f3640;
    max-width: 50px;
  }
  
  /* .selectBox{
    width:100%
  }
   */
  .selectBox .optionsContainer{
    color: #f5f6fa;
    max-height: 0;
    opacity: 0;
    transition: all 0.5s;
    overflow: hidden;
    border-radius: 8px;
    background: #2f3640;
    order: 1;
    margin-bottom:10px;
  }
  
  .selectBox .optionsContainer.active{
    max-height: 150px;
    opacity: 1;
    overflow-y: scroll;
  }
  
  .selectBox .optionsContainer.active + .selectedSku::after{
    transform: rotateX(180deg);
    top: -10px;
  }
  
  
  .selectBox .optionsContainer::-webkit-scrollbar{
    width: 8px;
    background: #0d141f;
    border-radius: 0 8px 8px 0;
  }
  
  .selectBox .optionsContainer::-webkit-scrollbar-thumb{
    background: #525861;
    border-radius: 8px 8px 8px 8px;
  }
  
  .selectBox .option,
  .selectedSku {
    padding: 2px 12px;
    cursor: pointer;
  }
  
  .selectBox label{
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    width: 100%;
    display: flex;
  }
  
  .selectBox .option:hover{
    background: #414b57;
  }
  
  .selectBox .option .radio{
    display: none;
  }
  
  .genericContainer{
    display: block;
    width: auto;
    margin: 24px;
    color: #f5f6fa;
    background-color: #8b98ac;
    box-shadow: 0 8px 0px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
    border-radius: 5px;
  }
  
 
  .genericContainer h1{
    text-indent: 10px;
    text-align: center;
    background: linear-gradient(90deg, #6e90c2 0%, #3a68ac 35%, #6e90c2 100%);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display:block;  
  }
  
  .genericContainer table{
    padding-left: 10px;
    padding-right: 10px;
    display: table;
    border-spacing: 0px;
    height: 100%;
    width: 100%;
  }

  .genericContainer .announcementHeaders th {
    font-size: 18px;
    border: 1px solid rgb(103, 103, 103);
    background: #6e90c2;
    padding: 2px;
    height: 25px;
  }

  .genericContainer .announcementRows th {
    font-size: 16px;
    border: 0.5px solid rgb(103, 103, 103);
    padding: 2px;
    text-overflow: ellipsis;
  }

  .genericContainer .announcementRows .announcementText {
    text-align: left;
    max-width: 500px;
    overflow: hidden;
    white-space: nowrap;
    transition: all;
  }

  .genericContainer .announcementRows .announcementTitle {
    text-align: left;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
  }

  .genericContainer .announcementRows .announcementText:hover {
    text-align: left;
    max-width: 500px;
    text-overflow: ellipsis;
    overflow: visible;
    white-space: normal;
  }

  .genericContainer .announcementRows .announcementTitle:hover {
    text-align: left;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: visible;
    white-space: normal;
  }

  .genericContainer .announcementRowsSelected {
    background-color: #647da3;
    white-space: normal;
  }

  .genericContainer .announcementRowsSelected th{
    background-color: #788394;
    white-space: normal;
    border: 1px solid rgb(103, 103, 103);
  }

  .genericContainer .announcementRows th i {
    text-align: left;
    max-width: 10px;
    padding: 0px;
  }



  .genericContainer input {
    display: inline-block;
    width: 500px;
    padding: 10px;
    margin: 10px;
    font-size: 12px;
  }
  
  .modal-main .newSkuLineInput{
    display: block;
    margin: 5px;
    padding: 6px;
    box-sizing:border-box;
  }

  .addAnnouncement h4{
    text-align:left;
    margin: 15px;
  }
  /********************************************************************************************************/

  /********************************************************************************************************/
  /* PRICING PAGE */
  .dropdownChannelSelect{
    margin: 10px;
    padding: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    background: #0d141f;
    border-radius: 8px 8px 8px 8px;
    color: #f5f6fa;
  }
  
  .orderRow th div ul {
    padding-left: 0%;
  }
  /********************************************************************************************************/

  /********************************************************************************************************/
  /* ANNOUNCEMENTS */

  .addAnnouncement{
    display: block;
  }

  .addAnnouncement .announcementInput{
    width:95%;
    margin-left:auto;
    margin-right:auto;
    margin:5px;
    padding:10px;
  }

  .announcementTable tbody tr th{
    width:fit-content;
    padding: 10px;
  }

  .quickLinksContainer{
    border-collapse: collapse;
    margin: 24px;
    color: #f5f6fa;
    background-color: #8b98ac;
    border-radius: 10px;
    box-shadow: 0 8px 0px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  }
  
  .quickLinksContainer h1{
    font-size: 28px;
    background-color: #6e90c2;
    background: linear-gradient(90deg, #6e90c2 0%, #3a68ac 55%, #6e90c2 100%);
    margin-bottom: 5px;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

  }

  .quickLinksContainer table {
    width: 100%;
    border: 2px black;
  }

  .quickLinksTable img{
    width: 50px;
    height: 50px;
    padding:0px;
  }

  .genericContainer h6{
    text-align: right;
    margin-right: 15px;
    margin-top:5px;
    margin-bottom:5px;
  }

  .genericContainer .filterDate{
    right: 15px;
    width: fit-content;
    padding: 2px;
    margin-top: 0px;
    display: inline-block;
    
  }

  .genericContainer .dateContainer p{
    display: inline-block;
  }

  .genericContainer .dateContainer{
    display: inline-block;
    align-items: end;
    position: absolute;
    right: 25px;
    height:fit-content;
  }

  .genericContainer .addAnnouncement #titleInput{
    box-sizing:inherit;
    font-size: 18px;
    padding: 0px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
    border:0px;

  }

  .genericContainer .addAnnouncement #bodyInput{
    font-size: 12px;
    padding: 2px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
    height: 100px;
    text-align: start;
  }

  .genericContainer .addAnnouncement h4{
    padding: 2px;
    margin: 2px;
    margin-left: 15px;
  }

  .genericContainer .addAnnouncement h5{
    padding: 2px;
    margin: 2px;
    margin-right: 20px;
    text-align: right;
  }

  .genericContainer .addAnnouncement .buttonContainer{
    height:fit-content;
    left: calc(100% - 200px);
    position: relative;
  }

  .genericContainer .addAnnouncement .buttonContainer .standardButton {
    margin: 5px;
    display:inline-block;
  }


  .fas.fa-solid.fa-thumbtack.selected{
    color: #a50000;
    border: none;
    background-color:#00000000;
  }

 .fas.fa-solid.fa-thumbtack:hover{
    color: #a50000;
    border: none;
    background-color:#00000000;
    cursor:pointer;
  }

  .fas.fa-solid.fa-thumbtack.selected:hover{
    color: white;
    border: none;
    background-color:#00000000;
  }


  
  /********************************************************************************************************/

  /********************************************************************************************************/
  /* FACTORY IMAGES */

.factoryDefectImages{
  margin: 10px;
  width: 20%;
  height: 20%;
  border: 2px solid #8b98ac;
}

  /********************************************************************************************************/

  /********************************************************************************************************/
  /* SEARCH BAR */

.searchBar{
  display: block;
  width: 80%;
  border: 2px solid #0d141f;
  border-radius: 4px;
  margin: 5px;
}

.dropdownSearchColumn{
  display: inline-flex;
  margin: 5px;
  margin-left: 15px;
  width: auto;
  border: 2px solid #0d141f;
  border-radius: 4px;
}

/********************************************************************************************************/

/********************************************************************************************************/
/* SORT BUTTON */

.fa-sort{
  display: inline-block;
  position: sticky;
}


/********************************************************************************************************/

/********************************************************************************************************/
/* SELECT PAGE OPTION */

.selectPage{
  z-index: 1;
}

.selectRows{
  display: inline-block;
  position: relative;
  left: 3%;
  padding: 5px;
  z-index: 1;
}


.pagination{
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;

}

.pagination button {
  color: black;
  background-color:#00000000;
  border-color: #00000000;
  float: left;
  padding: 4px 8px;
  text-decoration: none;
}

.pagination button.active {
  background-color: #8b98ac;
  color: white;
}

.pagination button:hover:not(.active) {background-color: #ddd;}

.totalEntries{
  width: 100%;
}

.totalEntries p{
  position: absolute;
  text-align:left;
  left: 3%;
  font-size: 14px;
  height: fit-content;
  margin-block-start: 5px;
  z-index: -5;
}

/********************************************************************************************************/

/********************************************************************************************************/
/* DOWNLOAD FILTERED DATA BUTTONS */


.createNewFiltered{
  padding: 3px;
  margin: 5px;
  float: right;
  background-color: rgb(42, 42, 42);
  color: #ffffff;
  border-color: 2px solid rgb(42, 42, 42);
  border-radius: 8px 8px 8px 8px;
}

.downloadFilteredButtons{
  margin-right: 3%;
}

/********************************************************************************************************/

/********************************************************************************************************/
/* FETCH FORM */

.parameterDescription{
  margin: 0px;
  margin-left: 10px;
  font-size: 14px;
}

.guidelinesImage{
  width: 500px;
  height: 250px;
  margin-left: 10px;
  border: 2px solid #8b98ac;

}

/********************************************************************************************************/

/********************************************************************************************************/
/* Product Description Inventory Management */




/********************************************************************************************************/

/********************************************************************************************************/
/* ERRORS */


.errorMessage {
  display:flex;
  align-items: center;
	border: 2px solid;
	margin: 10px;
	background-repeat: no-repeat;
	background-position: 10px center;
  width: 450px;
	max-width: 450px;
  padding: 15px;
}

.errorMessage {
	color: #D8000C;
	background-color: #FFBABA;
  margin: 5 auto;
  top: 50%;
  left: 50%;
}

.errorMessage p {
  font-size: 14px;
  margin-left: 15px;
}

.errorMessage i{
  scale: 400%;
  margin: 20px;
}

.hideErrorMessage{
  display: hidden;
}

.loginContainer .errorMessage{
  position: absolute;
  top: 80%;
  margin: 0px;
  left: calc(50% - 242px);
  z-index: 1;
}

.loginContainer .errorMessage p{
  height: fit-content;
  text-align: left;
  top: 50%;
  margin-left: 25px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.TableDiv .errorMessage {
  display: flex;
  position: relative;
  left: calc(50% - 225px);
  align-self: center;
  margin: 0px;
}

.match{
  color: #2d622d;
}

.noMatch{
  color: rgb(93, 44, 44);
}

/********************************************************************************************************/

/********************************************************************************************************/
/* SUCCESS */

.successMessage {
  display:flex;
  align-items: center;
	border: 2px solid;
	background-repeat: no-repeat;
	background-position: 10px center;
  width: auto;
	max-width: 450px;
  padding: 15px;
}

.successMessage {
	color: #2d622d;
	background-color: #b6d1b6;
  margin: 5 auto;
  top: 50%;
  left: 50%;
}

.successMessage p {
  font-size: 14px;
  margin-left: 15px;
}

.successMessage i{
  scale: 200%;
  margin: 20px;
}

.hideSuccessMessage{
  display: hidden;
}

.successMessage p{
  text-align:left;
}

.successMessage i{
  padding: 5px;
  margin: 5px;
}


/********************************************************************************************************/

/********************************************************************************************************/
/* DROPDOWN */

.dropdownWrapperHidden{
  display: none;
}

.dropdownWrapper{
  position: absolute;
  margin-left: 5px;
  background: white;
  border-radius: 5px;
  width: fit-content;
  z-index: 1000;
}

.formInlineButton .inlineButton{
  padding: 5px;
  margin-left: 10px;
  margin-bottom: 4px;
  display: block;
  width: auto;
  background-color: rgb(42, 42, 42);
  color: #ffffff;
  border-color: 2px solid rgb(42, 42, 42);
  border-radius: 8px 8px 8px 8px;
}

.formInlineButton .inlineButton:hover{
  max-width: 100%;
  width: fit-content;
  box-sizing: border-box;
  margin-left: 10px;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  overflow: visible;
  white-space: normal;
}

.formInlineButton .inlineButtonContainer{
  padding:0px;
}

.formInlineButton .dropdownWrapper{
  background-color: rgb(42, 42, 42);
  color: #ffffff;
}

.formInlineButton .dropdownWrapper .dropdownContent li{
  background-color: rgb(42, 42, 42);
  color: #ccc;
}

.dropdownWrapper .dropdownSearchBar{
  display: block;
  margin: 5px;
  width: auto;
  border: 1px solid #ccc; 
  border-radius: 10px; /* Adjust the radius to your desired value */
  outline: none;

}

.dropdownWrapper .dropdownContent{
  margin: 5px;
  padding-left: 5px;
  max-height: 250px;
  overflow-y: auto;
}

.dropdownWrapper .dropdownContentExtend{
  margin: 5px;
  padding-left: 0px;
  max-height: 40px;
  overflow-y: auto;
  width: fit-content;
}



.dropdownWrapper .dropdownContent li{
  height: 25px;
  background: white;
  color: #000000;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.dropdownWrapper .dropdownContentExtend li{
  height: 25px;
  padding: 0px;
  background: rgb(0, 0, 0);
  display: flex;
  cursor: pointer;
  align-items: center;  
  margin-right: 5px;
  margin-left: 5px;
  width: fit-content;
}

.dropdownWrapper .dropdownContent li:hover{
  height: 50px;
  background:#8b98ac;
  border: 2px solid black;
  padding: 5px;
  
}

.dropdownWrapper .dropdownContentExtend li:hover{
  height: 25px;
  background:#8b98ac;
}

.dropdownWrapper .dropdownContent .unselectableDropdown{
  color: grey;
  text-decoration: line-through;
}

.modal form label{
  display: inline;
}

/********************************************************************************************************/

/********************************************************************************************************/
/* PAGE */


.PageDiv h1{
  color: white;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.pieChartMenu{
  display: inline;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.pieChartMenu .dropdown{
  display: inline-block;
  width: auto;
  border: 1px solid #ccc; 
  border-radius: 10px; /* Adjust the radius to your desired value */
  outline: none;
  margin: 2px;
  padding: 2px;
  padding-top: 0px;
  height: auto;
  font-size: 16px;
  z-index: 10000;
}

.pieChartMenu .dropdown:hover{
  background:rgb(42, 42, 42);
  color: white;
}

.pieChartMenu .inlineButtonContainer{
  display: inline-block;
  width: auto;
  padding: 2px;
}

.pieChartMenu .dropdownWrapper{
  display: inline-block;
  z-index: 10000;
}

.pieChartMenu label{
  margin-right: 5px;
  margin-left: 5px;
  display:inline;
}

.pieChartContainer{
  display: block;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.pieChartContainer h3{
  color: #ffffff;
  font-weight:normal;
}



/********************************************************************************************************/

/********************************************************************************************************/
/* SWITCH BUTTON */

.cl-toggle-switch {
  position: relative;
  margin-left: 15px;
 }
 
 .cl-switch {
  position: relative;
  display: inline-block;
  margin: 0px;
 }
 /* Input */
 .cl-switch > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  right: -14px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgb(0, 0, 0, 0.38);
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
  margin:0px;
 }
 /* Track */
 .cl-switch > span::before {
  content: "";
  display: inline-block;
  border-radius: 7px;
  width: 60px;
  height: 14px;
  right:0px;
  top: 5px;
  position: absolute;
  background-color: rgb(0, 0, 0, 0.38);
  vertical-align: top;
  transition: background-color 0.2s, opacity 0.2s;
 }
 /* Thumb */
 .cl-switch > span::after {
  content: "";
  position: absolute;
  top: 1px;
  right: 45px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: background-color 0.2s, transform 0.2s;
 }
 /* Checked */
 .cl-switch > input:checked {
  right: -44px;
  background-color: #85b8b7;
 }
 
 .cl-switch > input:checked + span::before {
  background-color: #85b8b7;
 }
 
 .cl-switch > input:checked + span::after {
  background-color: #018786;
  transform: translateX(55px);
 }
 /* Hover, Focus */
 .cl-switch:hover > input {
  opacity: 0.04;
 }
 
 .cl-switch > input:focus {
  opacity: 0.12;
 }
 
 .cl-switch:hover > input:focus {
  opacity: 0.16;
 }
 /* Active */
 .cl-switch > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
 }
 
 .cl-switch > input:active + span::before {
  background-color: #8f8f8f;
 }
 
 .cl-switch > input:checked:active + span::before {
  background-color: #85b8b7;
 }
 /* Disabled */
 .cl-switch > input:disabled {
  opacity: 0;
 }
 
 .cl-switch > input:disabled + span::before {
  background-color: #ddd;
 }
 
 .cl-switch > input:checked:disabled + span::before {
  background-color: #bfdbda;
 }
 
 .cl-switch > input:checked:disabled + span::after {
  background-color: #61b5b4;
 }

 .cl-toggle-switch p{
  font-size: 16px;
  display: inline-block;
  text-align: left;
  margin-top:25px;
  margin-bottom:0px;
 }

/********************************************************************************************************/

/********************************************************************************************************/
/* Channel button for pricing page */

.channelSelectorContainer{
  display: block;
  float:right;
  margin-top: 1px;
}

.channelSelectorContainer .inlineButtonContainer{
  display: inline-block;
  width:auto;
  position:relative;
  padding-right: 40px;

}

.channelSelectorContainer p{
  display:inline-block;
  position:relative;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 5px;
  padding: 0px;
  font-size: 16px;
  color:white;
}

.channelSelectorContainer .dropdownWrapper li{
  background-color: #ffffff;

  width: fit-content;
}

.channelSelectorContainer .dropdownWrapper{
  background-color: #ffffff;
  z-index: 99999999;
  right: 40px;
  height: fit-content;
}

.channelSelectorContainer .dropdownWrapper .dropdownContentExtend{
  height: fit-content;
  max-height: 250px;
  width: fit-content;
  overflow-x: hidden;
}

.channelSelectorContainer .dropdownWrapper .dropdownContentExtend li{
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin: 2px;
  
}

.channelSelectorContainer .dropdownWrapper .dropdownContentExtend li:hover{
  height: 25px;
  background:#8b98ac;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 5px;
}


/********************************************************************************************************/

/********************************************************************************************************/
/* Dropdown for carton dimensions. */

.tableDescriptionAddNew .dropdownContentExtend li {
  background: white;
  width: 100%;
}

.tableDescriptionAddNew .dropdownContentExtend li:hover {
  background: white;
  width: 100%;
  background:#8b98ac;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 5px;
}

.tableDescriptionAddNew .dropdownContentExtend {
  background: white;
  max-height: 60px;
  width: 175px;
  overflow-x:hidden;
}

.tableDescriptionAddNew .dropdownWrapper {
  background: white;
  height: fit-content;
  width: 400px;
  padding: 5px;
  max-width: max-content;
}

/********************************************************************************************************/

/********************************************************************************************************/
/* xxxxxxx */

.thumbnailImage{
  width: 50px;
  height: 50px;
  margin: auto;
}



/********************************************************************************************************/

/********************************************************************************************************/
/* Login */

.loginModal{
  display:block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  padding-top: 10%;
}

.loginModal .modal-content {
  background-color: #fefefe;
  margin: 5px auto; /* 15% from the top and centered */
  width: 350px; /* Could be more or less, depending on screen size */
  padding: 15px;
  border: 2px solid #ffab9e71;
  border-radius: 8px 8px 8px 8px;
  color: #ffffff;
  background-color: rgb(79, 146, 126);
}

.loginModal .modal-content h1, h2, label, p{
  display: block;
  text-align:center;

}

.loginModal label{
  font-size: 10px;
}

.loginModal button{
  padding: 5px;
  margin: 5px;
  position:relative;
  width: 214px;
  transform: translateX(calc(65px));
  background-color: rgb(42, 42, 42);
  color: #ffffff;
  border-color: 2px solid rgb(42, 42, 42);
  border-radius: 8px 8px 8px 8px;
}

.loginModal .modal-content input{
  position:relative;
  width: 200px;
  transform: translateX(calc(65px));
  padding: 5px;
  margin: 5px;
  border-radius: 4px 4px 4px 4px;
  border: none;
}

.loginContainer .backgroundImage{
  display: block;
  border-bottom: 2px solid #435165;
  color: #000000;
  background-image: url(../public/mmilo-header-image-6-2.jpeg);
  background-size: cover;
  z-index: -1;
  position: absolute;
  height: 100vh;
  width: 100vw;
  opacity: 40%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.loginContainer{
  display: block;
}

.loginModal .registerContainer:hover .contact{
  display: block;
}

.loginModal .contact{
  display: none;
}

.loginModal .accountRequest{
  display: block;
}

.loginModal .registerContainer:hover .accountRequest{
  display: none;
}

.resetPassword{
  color: white;
  text-decoration: none;
}

.resetPassword:hover{
  color: red;
  cursor: pointer;
}

/********************************************************************************************************/

/********************************************************************************************************/
/*BULK UPLOAD*/


.bulkUploads .bulkUploadLabel{
  text-align:left;
  display:inline-block;
}

.bulkUploads .selectBox{
  width: fit-content;
  padding: 5px;
  margin: 5px;
}


.bulkUploads .selectSkuContainer{
  width: fit-content;
  display:inline-block;
}

.bulkUploads .dropdownWrapper{
  background:rgb(42, 42, 42);
}

.bulkUploads .dropdownContentExtend{
  width: fit-content;
  height: fit-content;
  max-height: fit-content;
  overflow:hidden;
}

.bulkUploads .dropdownWrapper li{
  background:rgb(42, 42, 42);
}

.bulkUploads .inlineButtonDisabled{
  opacity: 0.5;
  padding: 5px;
  margin: 10px;
  display: inline-block;
  background-color: rgb(42, 42, 42);
  color: #ffffff;
  border-color: 2px solid rgb(42, 42, 42);
  border-radius: 8px 8px 8px 8px;
}

.bulkUploads .buttonContainer{
  display: block;

}

/* .bulkUploads .buttonContainer button{
  transform: ;
} */

/********************************************************************************************************/

/********************************************************************************************************/
/* NOTIFICATION */

.notification{
  color:white;
  margin-left:15px;
}
/********************************************************************************************************/

/********************************************************************************************************/
/* xxxxxxx */
/* Note: 
display: block means it takes full width by default. 
Each block gets displayed in a new line. But you can set the width if you want. 
The next line would still display on the next block. 

display: inline means it takes whatever width that it needs.
Does not accept width or height properties.
Can be a parent of other inline elements.

display: inline-block means it is both.
Block element that doesn't have to start with a new line.
But also inline element wher eyou can set width and height.

*/
