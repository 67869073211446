.tableDescriptionImageContainer{
    display: flex;
    position:relative;
    margin: 10px;
  }

  .tableDescriptionImageContainer .standardUploadImageButton{
    display: none;
  }
  
  .tableDescriptionImageButtons .customImageUploadButton {
    padding: 5px;
    margin: 2px;
    display: flex;
    position: relative;
    background-color: rgb(42, 42, 42);
    color: #ffffff;
    border-radius: 8px 8px 8px 8px;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
  }

  .tableDescriptionImageButtons .customImageUploadButtonSelected{
    padding: 5px;
    margin: 2px;
    display: flex;
    position: relative;
    background-color: rgb(79, 146, 126);
    color: #ffffff;
    border-radius: 8px 8px 8px 8px;
    box-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.24);
    font-size: 14px;
    font-weight: normal;
    text-align: center;
  }