.TableDiv {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    width: 100%;
    margin: auto;
    padding: 5px;
    box-sizing: border-box;
}

.TableDiv .mainTable {
    width: calc(100% - 10px); /* Full width of TableDiv minus 5px margin on each side */
    text-align: left;
    padding: 5px;
    margin-top: 5px;
    border: 2px solid black;
    color: #000000;
    border-collapse: collapse;
    font-size: 12px;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19);
}

.TableDiv .mainTable tr th, .TableDiv .mainTable tr td {
    text-align: left;
    border: 2px solid rgb(103, 103, 103);
    padding: 3px;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.TableDiv .mainTable tr th:hover {
    text-align: left;
    border: 2px solid rgb(103, 103, 103);
    padding: 3px;
    width: auto;
    overflow: visible;
    white-space: normal;
}

.TableDiv .mainTable tr .mainTable-tableDescriptionEntry {
    text-overflow: unset;
    overflow: auto;
    white-space: normal;
    width: 500px;
    max-width: 500px;
}

.TableDiv .mainTable tr .mainTable-containerDescriptionTableHeader {
    text-overflow: unset;
    overflow: auto;
    white-space: normal;
    width: 250px;
    max-width: 500px;
}

.TableDiv .mainTable .mainTable-titleRow {
    color: #ffffff;
    background-color: #6e90c2;
    font-size: 18px;
}

.TableDiv .mainTable .itemRow:hover {
    background-color: rgb(206, 207, 207);
    color: #d7523e;
}

.TableDiv .mainTable-specialWidthColumn {
    width: 10vw;
}

.mainTable-downloadFilteredButtons {
    width: 100%;
    display: flex;
    justify-content: left;
    margin-top: 10px; /* Add some margin to separate from the table */
    margin-left: 10px;
}

.mainTable-selectPages {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px; /* Add some margin to separate from the table */
    margin-bottom: 15px; /* Add some margin to separate from the table */
    height: fit-content;
}

.mainTable-totalEntries{
    margin-left: 15px;
}

.mainTable-totalEntries p{
    margin-top: 0px;
    margin-bottom: 0px;
}

.mainTable-selectRows, .mainTable-totalEntries{
    height: auto; 
}

.mainTable-pagination button:hover {
/* Optional: Add some spacing between buttons */
    cursor:pointer;

}

.mainTable-pagination .mainTable-active {
    /* Add any necessary styling here */
    background-color:#fa826f;
}

.mainTable .lineItems {
    max-height: 50px;
}

.mainTable .lineItems:hover {
    max-height: 500px;
}

.channelSelectorContainer{
    margin-top: 15px !important;
}
